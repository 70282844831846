import React from 'react';
import './AllAvailableMM.css';
import MAMLogo from '../MAMLogo.png';

export default function AllAvailableMM(props) {

    return (
        <div className='allAvaDiv'>

            <div className='allAvaBackgroundDiv'>
                <h1 className='allAvaHeader'>All variations and pack sizes available from our main distributor</h1>
                <h1 className='allAvaMiniHeader'>Visit the where to buy page for other online stores including eBay, Amazon and many more</h1>

                <img className="allAvaLogo" src={MAMLogo} alt="MikeAndMatt.co.uk" onClick={() => window.open('https://mikeandmatt.co.uk/miama', '_blank')} />

                <h1 className='allAvaText'>Click the logo above to visit their dedicated MIAMA page</h1>



            </div>


        </div>
    )
};