import React, { useState } from 'react';
import './contactForm.css';
import { MIAMA_DataApiPost } from "./func.js";





function ContactFormComplete(props) {


    return (
        <div className={props.resultState ? "formSuccessDiv" : "formFailedDiv"}>



            {props.resultState ? (
                <div>
                    <h1 className="formResultHeader">Thank you</h1>
                    <p className="formResultText">We have recieved you message and we will be in contact soon!</p>
                </div>
            ) : (
                <div>
                    <h1 className="formResultHeader">It's not you it's us</h1>
                    <p className="formResultText">We sorry there has been an error submitting your form, please try again or email us at contact@miama.co.uk</p>
                </div>
            )}

        </div>
    )
};

function ContactFormForm(props) {
    //maybe max character limits? with countdown on message?
    const [typeInput, setTypeInputState] = useState('');
    const [nameInput, setNameInputState] = useState('');
    const [emailInput, setEmailInputState] = useState('');
    const [phoneInput, setPhoneInputState] = useState('');
    const [messageInput, setMessageInputState] = useState('');
    const [formErrorState, setFormErrorState] = useState(false);
    const [formErrorText, setFormErrorTextState] = useState([]);
    const [apiErrorState, setApiErrorState] = useState(false);

    function onTypeChange(e) {
        setTypeInputState(e.target.value);
    }

    function onNameChange(e) {
        setNameInputState(e.target.value);
    }

    function onEmailChange(e) {
        setEmailInputState(e.target.value);
    }

    function onPhoneChange(e) {
        setPhoneInputState(e.target.value);
    }

    function onMessageChange(e) {
        setMessageInputState(e.target.value);
    }

    async function formSubmit(e) {
        let formError = false;
        setFormErrorState(false);
        let errorTextArray = [];


        if (typeInput == "") {
            let errorText = "Contact Type cannot be blank";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        }

        if (nameInput == "") {
            let errorText = "Name cannot be blank";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        }

        let emailSymbol = "@";
        if (emailInput == "") {
            let errorText = "Email cannot be blank";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        } else if (!emailInput.includes(emailSymbol)) {
            let errorText = "Email address not valid";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        }

        if (isNaN(phoneInput)) {
            let errorText = "Phone number is not valid";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        }

        if (messageInput == "") {
            let errorText = "Message cannot be blank";
            errorTextArray.push(errorText);
            setFormErrorState(true);
            formError = true;
        }

        setFormErrorTextState(errorTextArray);

        if (!formError) {
            let formObject = { type: typeInput, name: nameInput, email: emailInput, phone: phoneInput, message: messageInput };

            let formData = JSON.stringify(formObject);
            let url = 'https://170ezhssti.execute-api.eu-west-2.amazonaws.com/prod/contact';
            try {
                const response = await MIAMA_DataApiPost(url, formData, setApiErrorState);
                if (response == "success") {
                    // if form works
                    props.setFormCompleteState(true);
                    props.setDefaultFormState(true);
                } else {
                    // if form fails
                    console.log("fail");
                    props.setFormCompleteState(false);
                    props.setDefaultFormState(true);
                }
            } catch (e) {
                setApiErrorState('error');
                props.setDefaultFormState(true);
                props.setFormCompleteState(false);
                console.log(e);
            }
        } else {
            console.log("Error in form, not submitting");
        }
    }

    return (


        <div className='formDiv'>
            {formErrorText.map(text =>
                <p className='errorText' key={text}>{text}</p>
            )}

            <label className='formLabel'>Contact Type*</label>
            <select className='formSelect' onChange={(e) => onTypeChange(e)} defaultValue="blank">
                <option value="blank" disabled >Please select an option</option>
                <option value="Public">Consumer (Public)</option>
                <option value="Retailer">Retailer</option>
                <option value="Wholesale">Wholesaler</option>
                <option value="School-Edu">School/Education</option>
                <option value="Other">Other</option>
            </select>

            <label className='formLabel'>Name*</label>
            <input className='formInput' type="text" placeholder="" onChange={(e) => onNameChange(e)} />

            <label className='formLabel'>Email*</label>
            <input className='formInput' type="text" placeholder="" onChange={(e) => onEmailChange(e)} />

            <label className='formLabel'>Phone</label>
            <input className='formInput' type="text" placeholder="" onChange={(e) => onPhoneChange(e)} />

            <label className='formLabel'>Message*</label>
            <textarea className='formTextArea' placeholder="" onChange={(e) => onMessageChange(e)} />


            <input className='formSubmit' type="submit" value="Submit" onClick={() => formSubmit()} />

        </div>
    )
};


export default function ContactForm() {
    const [defaultFormState, setDefaultFormState] = useState(false); //false is blank true is complete
    const [formCompleteState, setFormCompleteState] = useState(true); // true is success false is failed

    return (
        <div className="contactFormMainDiv">

            <h1 className='contactFormHeader'>Contact Us</h1>


            {defaultFormState ?  <ContactFormComplete resultState={formCompleteState} /> : <ContactFormForm setDefaultFormState={setDefaultFormState} setFormCompleteState={setFormCompleteState} />}

        </div>
    )
};