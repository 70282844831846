import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Products.css';
import GridItemComponent from './GridItemComponent.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MdfCirclesSE from './products/MdfCirclesSE.js';
import MdfCirclesRE from './products/MdfCirclesRE.js';
import MdfCirclesBE from './products/MdfCirclesBE.js';





function ProductsMain(props) {

    const navigate = useNavigate();
    function onItemClick(text) {
        let url = "/products/" + text;
        navigate(url);
    }

    return (
        <div>

            <h1 className='productsHeader'>Products</h1>

            <div className='productsGridDiv'>

                <GridItemComponent onItemClick={onItemClick} urlText="1001" text1="MDF Circles" text2="Square Edge" text3="Code: 1001" url="https://static.wixstatic.com/media/83d2e2_295fde592207473d845dcc07909a57bc~mv2.jpg" />

                <GridItemComponent onItemClick={onItemClick} urlText="1002" text1="MDF Circles" text2="Rounded Top Edge" text3="Code: 1002" url="https://static.wixstatic.com/media/83d2e2_295fde592207473d845dcc07909a57bc~mv2.jpg" />

                <GridItemComponent onItemClick={onItemClick} urlText="1003" text1="MDF Circles" text2="45 Degree Bevelled Edge" text3="Code: 1003" url="https://static.wixstatic.com/media/83d2e2_295fde592207473d845dcc07909a57bc~mv2.jpg" />

                <GridItemComponent onItemClick={onItemClick} urlText="1001" text1="MDF Squares" text2="Square Edge" text3="Code: 1004" url="https://static.wixstatic.com/media/83d2e2_d6377f214f5448868869301a9c6a66fc~mv2.jpg" />

                <GridItemComponent onItemClick={onItemClick} urlText="1002" text1="MDF Squares" text2="Rounded Top Edge" text3="Code: 1005" url="https://static.wixstatic.com/media/83d2e2_d6377f214f5448868869301a9c6a66fc~mv2.jpg" />

                <GridItemComponent onItemClick={onItemClick} urlText="1003" text1="MDF Squares" text2="45 Degree Bevelled Edge" text3="Code: 1006" url="https://static.wixstatic.com/media/83d2e2_d6377f214f5448868869301a9c6a66fc~mv2.jpg" />

            </div>

        </div>
    )
};





export default function Products(props) {

    const navigate = useNavigate();
    function onItemClick(text) {
        let url = "/products/" + text;
        navigate(url);
    }

    return (
        <div className="productsMainDiv">

            <Routes>
                <Route exact path="/" element={<ProductsMain />} />
                <Route path="/1001" element={<MdfCirclesSE />} />
                <Route path="/1002" element={<MdfCirclesRE />} />
                <Route path="/1003" element={<MdfCirclesBE />} />

            </Routes>

        </div>
    )
};