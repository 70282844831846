import React from 'react';
import './productPage.css';
import AllAvailableMM from './AllAvailableMM.js';

export default function MdfCirclesSE(props) {

    return (
        <div className='mainSingleProductDiv'>

            <h1 className='productHeader'>MDF Circles - Straight Edge</h1>
            <h1 className='productHeaderCode'>Product Code: 1001</h1>

            <img className="productMainImg" src="https://static.wixstatic.com/media/83d2e2_4c8df93ca8954c289bd38e74607cf8d2~mv2.jpg" alt="MDF Circles Straight Edge" />

            <AllAvailableMM />

<div className='productDescriptionTextDiv'>



<p className='productDescriptionText'>Versatile MDF circles are suitable for Embellishments, Wall Hanging, Wedding Decorations, Arts & Crafts etc - They are supplied in their sanded, bare wood state so they can be easily sprayed, painted or coated in resin, inks, glitter etc.</p>

<p className='productDescriptionText'>We manufacture these circles with either a square edge, rounded top edge or a 45-degree bevelled top edge.</p>

<p className='productDescriptionText'>Feel free to contact us if you require more information on this product or anything in the MIAMA range. You can find the form to contact us via the Contact Us page in the menu.</p>

<p className='productDescriptionText'>Available in 6mm, 9mm, 12mm, 18mm or 25mm thick MDF.</p>

<p className='productDescriptionText'>Available sizes - 10cm, 15cm, 20cm, 25cm, 30cm, 35cm, 40cm, 50cm and 60cm - All sizes are the diameter of the circle. MikeAndMatt.co.uk are able to supply our circles in custom sizes please contact them via their website for more information.</p>

<p className='productDescriptionText'>Pack sizes from 1 to 10 provide excellent value for money for larger projects or wholesale requirements. If you are a distributor or retailer please contact us for bulk orders.</p>

<p className='productDescriptionTextHeader'>Materials</p>
<p className='productDescriptionText'>Made from unpainted high-quality MDF which is FSC Certified and meets the EN 13986:2004 standard.</p>

<p className='productDescriptionText'>MDF is suitable for a wide number of internal uses with a smooth sanded finish and is completely free of natural defects.</p>


<br></br>
<p className='productDescriptionTextHeader'>See below for product codes and all available pack sizes</p>


</div>


            <div className='outerThicknessDiv'>
                <div className='singleThicknessDiv'>
                    <h1 className='productSingleHeader'>6mm Thick MDF</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Circle Size (width)</th><th>Pack Quantity</th><th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4}>100mm</td><td>Single (1)</td><td>1001-6-100-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-100-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-100-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-100-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>150mm</td><td>Single (1)</td><td>1001-6-150-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-150-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-150-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-150-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>200mm</td><td>Single (1)</td><td>1001-6-200-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-200-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-200-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-200-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>250mm</td><td>Single (1)</td><td>1001-6-250-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-250-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-250-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-250-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>300mm</td><td>Single (1)</td><td>1001-6-300-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-300-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-300-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-300-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>400mm</td><td>Single (1)</td><td>1001-6-400-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-400-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-400-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-6-400-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>500mm</td><td>Single (1)</td><td>1001-6-500-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-500-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-500-5</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>600mm</td><td>Single (1)</td><td>1001-6-600-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-6-600-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-6-600-5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='singleThicknessDiv'>
                    <h1 className='productSingleHeader'>9mm Thick MDF</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Circle Size (width)</th><th>Pack Quantity</th><th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4}>100mm</td><td>Single (1)</td><td>1001-9-100-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-100-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-100-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-100-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>150mm</td><td>Single (1)</td><td>1001-9-150-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-150-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-150-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-150-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>200mm</td><td>Single (1)</td><td>1001-9-200-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-200-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-200-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-200-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>250mm</td><td>Single (1)</td><td>1001-9-250-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-250-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-250-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-250-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>300mm</td><td>Single (1)</td><td>1001-9-300-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-300-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-300-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-300-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>400mm</td><td>Single (1)</td><td>1001-9-400-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-400-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-400-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-9-400-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>500mm</td><td>Single (1)</td><td>1001-9-500-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-500-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-500-5</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>600mm</td><td>Single (1)</td><td>1001-9-600-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-9-600-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-9-600-5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='singleThicknessDiv'>
                    <h1 className='productSingleHeader'>12mm Thick MDF</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Circle Size (width)</th><th>Pack Quantity</th><th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4}>100mm</td><td>Single (1)</td><td>1001-12-100-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-100-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-100-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-100-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>150mm</td><td>Single (1)</td><td>1001-12-150-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-150-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-150-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-150-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>200mm</td><td>Single (1)</td><td>1001-12-200-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-200-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-200-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-200-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>250mm</td><td>Single (1)</td><td>1001-12-250-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-250-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-250-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-250-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>300mm</td><td>Single (1)</td><td>1001-12-300-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-300-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-300-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-300-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>400mm</td><td>Single (1)</td><td>1001-12-400-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-400-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-400-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-12-400-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>500mm</td><td>Single (1)</td><td>1001-12-500-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-500-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-500-5</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>600mm</td><td>Single (1)</td><td>1001-12-600-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-12-600-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-12-600-5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='singleThicknessDiv'>
                    <h1 className='productSingleHeader'>18mm Thick MDF</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Circle Size (width)</th><th>Pack Quantity</th><th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4}>100mm</td><td>Single (1)</td><td>1001-18-100-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-100-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-100-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-100-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>150mm</td><td>Single (1)</td><td>1001-18-150-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-150-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-150-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-150-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>200mm</td><td>Single (1)</td><td>1001-18-200-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-200-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-200-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-200-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>250mm</td><td>Single (1)</td><td>1001-18-250-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-250-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-250-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-250-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>300mm</td><td>Single (1)</td><td>1001-18-300-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-300-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-300-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-300-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>400mm</td><td>Single (1)</td><td>1001-18-400-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-400-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-400-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-18-400-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>500mm</td><td>Single (1)</td><td>1001-18-500-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-500-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-500-5</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>600mm</td><td>Single (1)</td><td>1001-18-600-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-18-600-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-18-600-5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='singleThicknessDiv'>
                    <h1 className='productSingleHeader'>25mm Thick MDF</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>Circle Size (width)</th><th>Pack Quantity</th><th>Product Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={4}>100mm</td><td>Single (1)</td><td>1001-25-100-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-100-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-100-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-100-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>150mm</td><td>Single (1)</td><td>1001-25-150-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-150-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-150-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-150-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>200mm</td><td>Single (1)</td><td>1001-25-200-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-200-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-200-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-200-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>250mm</td><td>Single (1)</td><td>1001-25-250-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-250-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-250-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-250-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>300mm</td><td>Single (1)</td><td>1001-25-300-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-300-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-300-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-300-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={4}>400mm</td><td>Single (1)</td><td>1001-25-400-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-400-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-400-5</td>
                            </tr>
                            <tr>
                                <td>10 pack</td><td>1001-25-400-10</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>500mm</td><td>Single (1)</td><td>1001-25-500-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-500-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-500-5</td>
                            </tr>

                            <tr>
                                <td rowSpan={3}>600mm</td><td>Single (1)</td><td>1001-25-600-1</td>
                            </tr>
                            <tr>
                                <td>3 pack</td><td>1001-25-600-3</td>
                            </tr>
                            <tr>
                                <td>5 pack</td><td>1001-25-600-5</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};