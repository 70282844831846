
import './GridItemComponent.css';

export default function GridItemComponent(props) {
    // props:
    // text - name displayed under the image
    // image - name of the image from the images s3 bucket


    return (
        <div className='gridCompOuterDiv' onClick={() => props.onItemClick(props.urlText, props.text)}>
            <div className='gridCompImageDiv'><img className="gridCompImage" src={props.url} /></div>
            <div className='gridCompTextDiv'>
                <h1 className='gridCompText'>{props.text1}</h1>
                <h1 className='gridCompText'>{props.text2}</h1>
                <h1 className='gridCompText'>{props.text3}</h1>
            </div>
        </div>
    )
};