import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './Home.css';
import Products from './Products';
import WhereToBuy from './WhereToBuy.js';

export default function Home(props) {

    return (
        <div>
            <div className='homePageHeaderDiv'>
                <h1 className='homeHeader'>We produce high quality CNC and Laser Cut supplies right here in the UK, our product range is just starting to grow so keep an eye out for new products and designs.</h1>
                <h1 className='homeHeader'>Our products can be found on many online retailers including Etsy, ebay, Amazon and OnBuy but our main distributor is MikeAndMatt.co.uk, their website stocks our full range of products in all available pack sizes.</h1>
            </div>
            <Products />

            <WhereToBuy />

        </div>
    )
};