import logo from './logo.svg';
import ContactForm from './contactForm.js';
import Home from './Home.js';
import Products from './Products.js';
import WhereToBuy from './WhereToBuy.js';
import './AppDesktop.css';
import './AppMobile.css';
import Sidebar from './sidebar.js';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {

  const [desktopView, setDesktopViewState] = useState(true);  // used by sidebar to decide what size the icons are as mobile view icons are smaller
  const [fullSidebar, setFullSidebarState] = useState(true); // used to trigger the movement between full and minimised sidebar, if true sidebar is full

  useEffect(() => {
    let viewportwidth = window.innerWidth;
    if (viewportwidth <= 700) {
      setDesktopViewState(false);
      setFullSidebarState(false);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Sidebar fullSidebarValue={fullSidebar} setFullSidebarState={setFullSidebarState} desktopView={desktopView} />
        <div className='MainBodyDiv'>
          <header className="App-header">
            <img src={logo} className="main-logo" alt="logo" />
          </header>
          {desktopView ? (
            <div className="headingDiv">
              <h1 className="mainHeading">Quality Arts & Craft Supplies - All designed and cut in the UK</h1>
            </div>) : (
            <div className="headingDiv">
              <h1 className="mainHeading">Quality Arts & Craft Supplies</h1>
              <h1 className="mainHeading">All designed and cut in the UK</h1>
            </div>)
          }

         
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/products/*" element={<Products />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/where-to-buy" element={<WhereToBuy />} />
            </Routes>
 
        </div>

        <div className='site-footer'>
          <p>Copyright &copy; MIAMA 2022</p>
          <p>Logo Registered Trademark</p>
        </div>

      </div>

    </Router>
  );
}

export default App;
