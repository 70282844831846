//  https://170ezhssti.execute-api.eu-west-2.amazonaws.com/prod/contact

function handleErrors(response, apiErrorStateSet) {
    if (!response.ok) {
        apiErrorStateSet('error');
    }
    return response;
};


export async function MIAMA_DataApiPost(url, body, apiErrorStateSet) {
    try {
        const submit = await fetch(url, { method: 'POST', body: body });
        const response = handleErrors(submit, apiErrorStateSet);
        const data = response.json();
        return (data);
    } catch (e) {
        apiErrorStateSet('error');
        console.log(e);
    }
};