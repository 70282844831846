import React, { useState, useEffect } from 'react';
import './WhereToBuy.css';
import AllAvailableMM from './products/AllAvailableMM.js';

export default function WhereToBuy(props) {


    return (
        <div className="wtbMainDiv">

            <h1 className='wtbHeader'>Where to buy</h1>


            <AllAvailableMM />
            
            <img className="wtbCompanyLogo" src="https://upload.wikimedia.org/wikipedia/commons/e/e6/OnBuy_Logo_SVG.svg" alt="OnBuy" onClick={()=>window.open('https://www.onbuy.com/gb/','_blank')}/>
            Coming Soon
            <img className="wtbCompanyLogo" src="https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg" alt="AMAZON" onClick={()=>window.open('https://amazon.co.uk','_blank')}/>
            Coming Soon
            <img className="wtbCompanyLogo" src="https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg" alt="ebay" onClick={()=>window.open('https://www.ebay.co.uk/sch/mikeandmattcouk/m.html?_nkw=&_armrs=1&_ipg=&_from=','_blank')}/>

            <img className="wtbCompanyLogo" src="https://upload.wikimedia.org/wikipedia/commons/8/89/Etsy_logo.svg" alt="etsy" onClick={()=>window.open('https://www.etsy.com/uk/shop/MikeAndMattCoUk','_blank')}/>


        </div>
    )
};