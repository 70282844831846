import React, { useState, useEffect } from 'react';
import './sidebar.css';
import fullLogo from './logo.svg';
import { useNavigate } from 'react-router-dom';
import minLogo from './logo.svg';
import { FiMinimize2 } from 'react-icons/fi';
import { RiContactsFill, RiProductHuntLine } from 'react-icons/ri';
import { ImHome } from 'react-icons/im';
import { FaShoppingBag } from 'react-icons/fa';
import { BiError } from 'react-icons/bi';
import { BsArrowsAngleExpand } from 'react-icons/bs';

function iconFinder(icon, desktopView) {
    let size = '45px';
    if (!desktopView) {
        size = '35px';
    }

    switch (icon) {
        case 'Home':
            return (
                <ImHome color='#ffffff' size={size} />
            );
        case 'Products':
            return (
                <RiProductHuntLine color='#ffffff' size={size} />
            );
        case 'Contact':
            return (
                <RiContactsFill color='#ffffff' size={size} />
            );
        case 'Where to buy':
            return (
                <FaShoppingBag color='#ffffff' size={size} />
            );
        default:
            return (
                <BiError color='#ffffff' size={size} />
            );
    }
}


function SidebarItemWithDropdown(props) {
    const navigate = useNavigate();

    function onDropdownItemClick(url) {
        navigate(url);
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            props.dropdownStateChange(props.name, currentState);
            props.setFullSidebarState(false);
        }
    }

    function onMainItemClick() {
        let currentState = props.dropdownState;
        props.dropdownStateChange(props.name, currentState);
    }

    return (
        <div className="sidebar-item-padding-div">
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item' onClick={() => onMainItemClick()}>
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>

                <div className={props.dropdownState ? 'sidebar-item-dropdown-outer' : 'sidebar-item-dropdown-outer-hide'}>
                    {props.dropdowns.map((dropdownitem) => {
                        return (
                            <div className='sidebar-item-dropdown-item' onClick={() => (onDropdownItemClick(dropdownitem["url"]))}>
                                <span className={props.fullSidebarValue ? 'sidebar-dropdown-item-text' : 'sidebar-item-text-min'}>{dropdownitem["name"]}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}





function SidebarItemWithoutDropdown(props) {
    const navigate = useNavigate();

    function onItemClick(url) {
        navigate(url)
        props.closeAllDropdowns();
        if (!props.desktopViewState) { // if mobile view
            let currentState = props.dropdownState;
            {//props.dropdownStateChange(props.name, currentState);
            }
            console.log("mobile view clicked");
            props.setFullSidebarState(false);
        }
    }

    return (
        <div className="sidebar-item-padding-div" onClick={() => onItemClick(props.url)}>
            <div className='sidebar-item'>
                <div className='sidebar-item-main-item'>
                    <div className='sidebar-icon'>{iconFinder(props.name, props.desktopViewState)}</div>
                    <span className={props.fullSidebarValue ? 'sidebar-item-text' : 'sidebar-item-text-min'}>{props.name}</span>
                </div>
            </div>
        </div>
    )
}


export default function Sidebar(props) {

    const navigate = useNavigate();

    function returnToDashboard() {
        navigate('/')
    }

    function toggleSidebarVisibilityStatus() { // toggles between full and minimised sidebar

        if (props.fullSidebarValue) {
            props.setFullSidebarState(false);
        } else {
            props.setFullSidebarState(true);
        }
    }

    function closeAllDropdowns() {

    }


    function handleDropdownTrigger(name, currentState) {
        props.setFullSidebarState(true);
    }
    

    return (
        <div className={props.fullSidebarValue ? 'sidebar-full' : 'sidebar-minimised'}>
            <div className='sidebar-logo-div' onClick={() => returnToDashboard()}><img className={props.fullSidebarValue ? 'sidebar-logo-full' : 'sidebar-logo-min'} src={props.fullSidebarValue ? fullLogo : minLogo} alt='logo' /></div>

            <div>
                <SidebarItemWithoutDropdown name="Home" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Products" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/products" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Contact" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/contact" />
            </div>

            <div>
                <SidebarItemWithoutDropdown name="Where to buy" fullSidebarValue={props.fullSidebarValue} closeAllDropdowns={closeAllDropdowns} setFullSidebarState={props.setFullSidebarState} desktopViewState={props.desktopView} url="/where-to-buy" />
            </div>


            {props.desktopView ? null : (
                <div className='sidebar-minimise-div' onClick={() => toggleSidebarVisibilityStatus()}>
                    <div className='sidebar-icon'>{props.fullSidebarValue ? <FiMinimize2 color='#ffffff' size='35px' /> : <BsArrowsAngleExpand color='#ffffff' size='35px' />}</div>;
                    <span className={props.fullSidebarValue ? 'sidebar-bottom-item-text' : 'sidebar-item-text-min'}>Minimise Sidebar</span>
                </div>)
            }

        </div>
    )
}